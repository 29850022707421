import React from 'react'
import { product } from '../Product'

const Name = () => {
  return (

        <h1>{product[0].name}</h1>
      
   
  )
}

export default Name